import { FC, Suspense, lazy, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import store from 'store'
import LanguageTags, { ENGLISH_AUSTRALIA, ENGLISH_NEW_ZEALAND } from 'i18n/locales'
import CodeRedemptionView from 'views/CodeRedemptionView'
import ProtectedRoute from 'components/ProtectedRoute'
import { useLocale } from 'hooks/url'
import Analytics from 'utils/analytics'
import { getAuthCookies } from 'utils/auth'
import { setIdleLogoutTime  } from 'utils/idle'

import styles from './App.module.scss'

const Header = lazy(() => import('components/Header'))
const Footer = lazy(() => import('components/Footer'))
const Gus = lazy(() => import('components/Gus'))
const NotFoundView = lazy(() => import('views/NotFoundView'))

// LanguageTagRegex is a regex to validate the format of a 5 character locale
// The format is a hyphen separated by two alphabetical characters on each side. (e.g: 'en-us')
const LanguageTagRegex = '[a-z]{2}-[a-z]{2}'

// App is the base React component which acts as a wrapper for all other subscriptions and views
const App: FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <HelmetProvider>
          <OpenGraphTags />
          <Suspense fallback={<></>}>
            <Gus />
          </Suspense>
          <Suspense fallback={<></>}>
            <Header />
          </Suspense>
          <Routes />
          <Suspense fallback={<></>}>
            <Footer /> 
          </Suspense>
        </HelmetProvider>
      </Provider>
    </Router>
  )
}

// Routes selectively renders views according to the first path to match the current URL
const Routes: FC = () => {
  const locale = useLocale()

  if(!LanguageTags.has(locale)) {
    // en-nz and en-au are not locales that have their own separate translations so they exist only to default to en-gb
    if(locale === ENGLISH_AUSTRALIA || locale === ENGLISH_NEW_ZEALAND) window.location.assign(`/en-gb${window.location.search}`)
    else window.location.assign(`/en-us${window.location.search}`)
  }

  useEffect(() => {
    setIdleLogoutTime()
    const analytics = Analytics()
    const { accessToken } = getAuthCookies()
    if(!accessToken) {
      // checks access token to prevent pageload POST from running twice (once here and once after the /can_redeem endpoint has returned)
      // the POST request will still run twice on first login
      analytics.postData('/page-load/v02/record', {})
    }
  }, [])

  return (
    <div className={styles.root}>
      <Switch>
        <ProtectedRoute exact path={`/:locale(${LanguageTagRegex})`}>
          <CodeRedemptionView />
        </ProtectedRoute>
        <Route path='*'>
          <Suspense fallback={<></>}>
            <NotFoundView />
          </Suspense>
        </Route>
      </Switch>
    </div>
  )
}

const OpenGraphTags = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
        {/*Facebook Specific Open Graph Tags*/}
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:image" content={t('img.tcglLogo')} />
        <meta property="og:description" content={t('siteDescription')} />
 
        {/*Twitter Specific Open Graph Tags*/}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={t('meta.title')} />
        <meta property="twitter:image" content={t('img.tcglLogo')} />
        <meta property="twitter:description" content={t('siteDescription')} />
    </Helmet>
  )
}

export default App