import axios from 'axios'

import { AppDispatch } from 'store'
import { getAuthParameters, getScreenName, redirectToLogin } from 'utils/auth'
import { getCookie, setCookie } from 'utils/cookie'
import { REFRESH_TOKEN_TIME, ACCESS_TOKEN_TIME } from 'utils/config'

export const getAuthTokens = (authorizationCode: string | null) => {
    const { REACT_APP_AUTH_URL, REACT_APP_REDIRECT_URL } = process.env

    return async (dispatch: AppDispatch) => {
        if(!authorizationCode) return
        dispatch({type: 'GET_AUTH_TOKENS', payload: {}})
        try {
            const authParameters = getAuthParameters()
            if(!authParameters) {
                throw new Error('missing auth parameters')
            }

            const { state, codeVerifier } = authParameters
            const params = new URLSearchParams()
            params.append('grant_type', 'authorization_code')
            params.append('code', authorizationCode)
            params.append('state', state)
            params.append('client_id', 'rainier-code-redemption')
            params.append('code_verifier', codeVerifier)
            params.append('redirect_uri', REACT_APP_REDIRECT_URL as string)

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }

            const response = await axios.post(`${REACT_APP_AUTH_URL}/token`, params, config)
            const { access_token, refresh_token } = response.data

            // PTCS access tokens are valid for 1 hour but for security reasons, going to client-side invalidate after 15 minutes
            // if the refresh token is still valid, another access token should be fetched automatically
            const accessCookieConfig = {
                name: 'a_token',
                value: access_token,
                expireTimeInSeconds: ACCESS_TOKEN_TIME,
                secure: true
            }
            setCookie(accessCookieConfig)

            // PTCS refresh tokens are valid for 30 days but for security, going to client-side invalidate after 30 minutes
            // 1800 seconds in half an hour
            const refreshCookieConfig = {
                name: 'r_token',
                value: refresh_token,
                expireTimeInSeconds: REFRESH_TOKEN_TIME,
                secure: true
            }
            setCookie(refreshCookieConfig)
            const screenName = getScreenName()
            dispatch({type: 'GET_AUTH_TOKENS_SUCCESS', payload: { screenName }})
            
            window.location.assign('/')
        } catch (err) {
            dispatch({type: 'GET_AUTH_TOKENS_FAILURE', payload: {}})
            redirectToLogin()
        }
    }
}

export const refreshTokens = () => {
    return async (dispatch: AppDispatch) => {
        dispatch({type: 'GET_REFRESHED_TOKENS', payload: {}})
        await refreshTokenAPICall()
        const screenName = getScreenName()
        dispatch({type: 'GET_REFRESHED_TOKENS_SUCCESS', payload: { screenName }})
    }
}

export const refreshTokenAPICall = async () => {
    try {
        const { REACT_APP_AUTH_URL } = process.env
        const refreshToken = getCookie('r_token')
        if(!refreshToken) {
            throw new Error('no refresh token found')
        }
    
        const params = new URLSearchParams()
        params.append('grant_type', 'refresh_token')
        params.append('client_id', 'rainier-code-redemption')
        params.append('refresh_token', refreshToken)
    
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    
        const response = await axios.post(`${REACT_APP_AUTH_URL}/token`, params, config)
        const { access_token, refresh_token } = response.data
    
        // PTCS access tokens are valid for 1 hour but for security reasons, going to client-side invalidate after 15 minutes
        // if the refresh token is still valid, another access token should be fetched automatically
        const accessCookieConfig = {
            name: 'a_token',
            value: access_token,
            expireTimeInSeconds: ACCESS_TOKEN_TIME,
            secure: true
        }
        setCookie(accessCookieConfig)
    
        // PTCS refresh tokens are valid for 30 days but for security, going to client-side invalidate after 30 minutes
        // 1800 seconds in half an hour
        const refreshCookieConfig = {
            name: 'r_token',
            value: refresh_token,
            expireTimeInSeconds: REFRESH_TOKEN_TIME,
            secure: true
        }
        setCookie(refreshCookieConfig)
    } catch (e) {
        redirectToLogin()
    }
}

export const logout = (redirectLink: string) => {
    // cookies are automatically removed from the browser once the expiration date has passed
    document.cookie = 'a_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = 'r_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    window.location.assign(redirectLink)
}